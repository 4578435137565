export const maxKeywordInputLength = 300

interface BusinessNameResultsProp {
  inputKeyword: string
}

const BusinessNameResults = ({ inputKeyword }: BusinessNameResultsProp) => {
  return <h1>Business Name Generator Results for the input "{inputKeyword}"</h1>
}

export default BusinessNameResults
