import styled from 'styled-components'
import { FormError, tokens } from '@vp/swan'

// FormErrorWrapper reserves height for the FormError to toggle its appearance
// beneath a TextInput and prevent content layout shift.
// Swan team have said this space reservation is the responsibility of the page
// context where the FormError is used.
// See: https://vistaprint.slack.com/archives/CB9FVD94J/p1724810010549059

interface FormErrorWrapperProps {
  errorMessage: string
}

const Container = styled.div`
  height: ${tokens.SwanSemSpace6};
`

const FormErrorWrapper = ({ errorMessage }: FormErrorWrapperProps) => {
  return (
    <Container>
      {errorMessage && (
        <FormError marginTop={0} paddingTop={2} role='status'>
          {errorMessage}
        </FormError>
      )}
    </Container>
  )
}

export default FormErrorWrapper
