import { useState } from 'react'
import { useTrackPage } from '../../utils/analytics'
import BusinessNameResults
  from './components/BusinessNameResults/BusinessNameResults'
import Hero from './components/Hero/Hero'
import HowItWorks from './components/HowItWorks/HowItWorks'

const BusinessNameGeneratorPage = () => {
  useTrackPage('Business Name Generator')
  const [showResultsPage, setShowResultsPage] = useState<boolean>(false)
  const [inputKeyword, setInputKeyword] = useState<string>('')

  const handleSubmit = () => {
    setShowResultsPage(true)
  }

  return showResultsPage
    ? <BusinessNameResults inputKeyword={inputKeyword} />
    : (
      <>
        <Hero
          onSubmit={() => handleSubmit()}
          inputKeyword={inputKeyword}
          onSearchInputChange={(value) => setInputKeyword(value)}
        />
        <HowItWorks />
      </>
      )
}

export default BusinessNameGeneratorPage
