import {
  BoundedContent,
  Column,
  FlexBox,
  GridContainer,
  H2,
  H3,
  Icon,
  Link,
  responsive,
  Row,
  Typography
} from '@vp/swan'
import { useTrackNavigationClicked } from '../../../../utils/analytics'

const ResponsiveFlexBox = responsive(FlexBox)
const ResponsiveIcon = responsive(Icon)

const HowItWorks = () => {
  const logomakerCtaRef = useTrackNavigationClicked(
    'Business Name Generator',
    'HowItWorks',
    '/logomaker',
    'freeLogomaker'
  )
  const logoDesignCtaRef = useTrackNavigationClicked(
    'Business Name Generator',
    'HowItWorks',
    '/logo-design',
    'startAContest'
  )

  return (
    <BoundedContent marginY='between-sections'>
      <H2 fontSkin='title-section' marginBottom='between-subsections'>
        Build your brand, all in one place.
      </H2>
      <GridContainer>
        <Row>
          <Column span={4} marginBottom={{ xs: 'between-subsections' }}>
            <ResponsiveFlexBox
              xs={{ flexDirection: 'row', justifyContent: 'flex-start' }}
              sm={{ flexDirection: 'column', justifyContent: 'flex-start' }}
              md={{ flexDirection: 'column', justifyContent: 'center' }}
              alignItems='flex-start'
              style={{ position: 'relative' }}
            >
              <ResponsiveIcon
                iconType='magic'
                xs={{ size: '48p' }}
                sm={{ size: '60p' }}
                marginBottom={{ xs: 0, sm: '7', md: '7', lg: '7', xl: '7' }}
                marginRight={{ xs: '7' }}
              />
              <FlexBox flexDirection='column' alignItems='flex-start'>
                <H3 fontSkin='title-subsection'>Get business name ideas</H3>
                <Typography>
                  Get inspired with creative name ideas to kick-start your new
                  venture.
                </Typography>
              </FlexBox>
            </ResponsiveFlexBox>
          </Column>
          <Column span={4} marginBottom={{ xs: 'between-subsections' }}>
            <ResponsiveFlexBox
              xs={{ flexDirection: 'row', justifyContent: 'flex-start' }}
              sm={{ flexDirection: 'column', justifyContent: 'flex-start' }}
              md={{ flexDirection: 'column', justifyContent: 'center' }}
              alignItems='flex-start'
              style={{ position: 'relative' }}
            >
              <ResponsiveIcon
                iconType='sparkle'
                xs={{ size: '48p' }}
                sm={{ size: '60p' }}
                marginBottom={{ xs: 0, sm: '7', md: '7', lg: '7', xl: '7' }}
                marginRight={{ xs: '7' }}
              />
              <FlexBox flexDirection='column' alignItems='flex-start'>
                <H3 fontSkin='title-subsection'>Create your logo on any budget</H3>
                <Typography>
                  Make your vision a reality with our{' '}
                  <Link href='/logomaker' ref={logomakerCtaRef}>
                    free Logomaker
                  </Link>{' '}
                  or{' '}
                  <Link href='/logo-design' ref={logoDesignCtaRef}>
                    professional design services.
                  </Link>
                </Typography>
              </FlexBox>
            </ResponsiveFlexBox>
          </Column>
          <Column span={4}>
            <ResponsiveFlexBox
              xs={{ flexDirection: 'row', justifyContent: 'flex-start' }}
              sm={{ flexDirection: 'column', justifyContent: 'flex-start' }}
              md={{ flexDirection: 'column', justifyContent: 'center' }}
              alignItems='flex-start'
              style={{ position: 'relative' }}
            >
              <ResponsiveIcon
                iconType='printedProducts'
                xs={{ size: '48p' }}
                sm={{ size: '60p' }}
                marginBottom={{ xs: 0, sm: '7', md: '7', lg: '7', xl: '7' }}
                marginRight={{ xs: '7' }}
              />
              <FlexBox flexDirection='column' alignItems='flex-start'>
                <H3 fontSkin='title-subsection'>Bring your brand to life</H3>
                <Typography>
                  We’ve got the tools you need to grow your business online and
                  print it on (almost) anything.
                </Typography>
              </FlexBox>
            </ResponsiveFlexBox>
          </Column>
        </Row>
      </GridContainer>
    </BoundedContent>
  )
}

export default HowItWorks
